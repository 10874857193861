import i18n from '@/i18n';

///////////////////////////////////////
/// Las mutaciones son los setters de la aplicacion
/// son aquellas acciones que realizan los cambios de estados
//////////////////////////////////////

const mutations = {
  setLayout(state, payload) {
    state.layout = payload;
  },
  toggleSideBar(state, value) {
    state.is_show_sidebar = value;
  },
  toggleSearch(state, value) {
    state.is_show_search = value;
  },
  toggleLocale(state, value) {
    value = value || 'en';
    i18n.global.locale = value;
    localStorage.setItem('i18n_locale', value);
    state.locale = value;
  },

  toggleDarkMode(state, value) {
    //light|dark|system
    value = value || 'light';
    localStorage.setItem('dark_mode', value);
    state.dark_mode = value;
    if (value == 'light') {
      state.is_dark_mode = false;
    } else if (value == 'dark') {
      state.is_dark_mode = true;
    } else if (value == 'system') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        state.is_dark_mode = true;
      } else {
        state.is_dark_mode = false;
      }
    }

    if (state.is_dark_mode) {
      document.querySelector('body').classList.add('dark');
    } else {
      document.querySelector('body').classList.remove('dark');
    }
  },

  toggleMenuStyle(state, value) {
    //horizontal|vertical|collapsible-vertical
    value = value || '';
    localStorage.setItem('menu_style', value);
    state.menu_style = value;
    if (!value || value === 'vertical') {
      state.is_show_sidebar = true;
    } else if (value === 'collapsible-vertical') {
      state.is_show_sidebar = false;
    }
  },

  toggleLayoutStyle(state, value) {
    //boxed-layout|large-boxed-layout|full
    value = value || '';
    localStorage.setItem('layout_style', value);
    state.layout_style = value;
  },

  /////////////////////////////////////////
  ///////////  Mutaciones de Ibazan
  ////////////////////////////////////////

  is_logged(state, value) {
    // value = value || '';
    // localStorage.setItem('is_logged', value);
    sessionStorage.setItem('is_logged', value);
    state.is_logged = value;
  },

  reload_page(state, value) {
    localStorage.setItem('reloadPage', value);
    state.reloadPage = value;
  },

  user_logIn(state, data) {
    data = data || '';
    // localStorage.setItem(state.user, JSON.stringify(data));
    // localStorage.setItem(state.user, data);
    state.user = data;
  },

  // user_logOut(state, data) {
  //     data = data || '';
  //     localStorage.setItem(state.user, data);
  //     state.user = data;
  // },
  user_logOut(state) {
    const user = {
      id: '',
      nombre: '',
      cedula: '',
      estado: '',
      idRol: '',
      idSucursal: '',
    };
    // localStorage.setItem(state.user, JSON.stringify(user));
    // localStorage.setItem(state.user, user);
    // localStorage.setItem('is_logged', false);
    sessionStorage.setItem('is_logged', false);
    state.user = user;
  },
};

export default mutations;
